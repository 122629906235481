import MarketplaceHttpClient from '@/network/httpClients/MarketplaceHttpClient'
import useAuthMutation from 'hooks/useAuthMutation'

import { MarketplacePreAlphaCreatePaymentRequest } from '../types'

// This doesn't actually need auth however the user does need to be logged in.
export const useCreatePreAlphaAccessPayment = () =>
    useAuthMutation({
        showErrorToast: true,
        mutationFn: (payload: MarketplacePreAlphaCreatePaymentRequest, token) =>
            MarketplaceHttpClient.setAccessToken(token).CreatePreAlphaAccessPayment(payload),
    })
